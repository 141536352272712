<template>
  <div>
    <b-card class="text-black">
      <h2 class="m-0 mb-2">Add User Data</h2>

      <b-form @submit.prevent id="feedback-add-user-data">
        <validation-observer ref="userDetailFormValidation">
          <div>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <label>CNIC</label><span class="text-danger">*</span>
                  <validation-provider
                    #default="{ errors }"
                    name="cnic"
                    rules="required|integer|positive|length:13"
                  >
                    <b-form-input
                      id="cnic"
                      v-model="cnic"
                      :state="errors.length > 0 ? false : null"
                      placeholder="1234567890001"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group>
                  <label>Name</label><span class="text-danger">*</span>
                  <validation-provider
                    #default="{ errors }"
                    name="Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <label>Contact No.</label>
                  <!-- <span class="text-danger">*</span> -->
                  <validation-provider
                    #default="{ errors }"
                    name="Contact No"
                    rules="required|integer|length:11"
                  >
                    <b-form-input
                      id="mobile"
                      v-model="mobile"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Contact No"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col md="3">
                    <b-form-group label-for="hospital">
                      <validation-provider
                        #default="{ errors }"
                        name="Hospital"
                        rules="required"
                      >
                        <label>Hospital</label>
                        <v-select
                          v-model="hospital"
                          placeholder="Hospital"
                          :options="hospitalList"
                          label="name"
                          class="v-style-chooser"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col> -->
            </b-row>
          </div>
          <b-form-group>
            <b-button
              id="submit"
              type="submit"
              variant="primary"
              pill
              class="mr-1"
              @click="validationForm"
            >
              Submit
            </b-button>
          </b-form-group>
        </validation-observer>
      </b-form>
      <b-overlay :show="show" spinner-variant="primary" no-wrap />
    </b-card>
  </div>

  <!-- </b-modal> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import VueSelectPaginated from "@/components/ui/VueSelectPaginated.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import util from "@/util.js";
import { heightTransition } from "@core/mixins/ui/transition";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    VueSelectPaginated,
    VuePerfectScrollbar,
    draggable,
  },
  mixins: [util, heightTransition],
  props: { modalClosed: "modalClosed" },
  data() {
    return {
      user: '',
      show: false,
      required,
      cnic: "",
      name: "",
      mobile: "",
      hospital: "",
      hospitalList: [],
    };
  },
  async mounted() {
    const userData = await this.getLoggedInUser;
    this.user = userData.name;
  },

  async created() {
    await this.getHospitals();
  },
  methods: {
    ...mapActions({
      getUser: "appData/getUser",
      getUsers: "appData/getUsers",
      hospitals: "appData/hospitals",
      getUsersUnPaginated: "appData/getUsersUnPaginated",
      createFeedback: "appData/createFeedback",
    }),

    async getHospitals() {
      const res = await this.hospitals();

      const hospitalsData = res.data.results;

      this.hospitalList = hospitalsData.map((hospital) => ({
        value: hospital.id,
        name: hospital.name,
      }));
    },
    async validationForm() {
      let success = true;
      if (success) {
        this.homeForm = false;
        success = await this.$refs.userDetailFormValidation.validate();
        if (success) {
          await this.submit();
        }
      }
    },
    async submit() {
      this.show = true;
      let hospital;
      if (this.user === "Pak Emirates Military Hospital") {
        hospital = 3;
      }
      if (this.user === "CMH Lahore") {
        hospital = 2;
      }
      if (this.user === "CMH Rawalpindi") {
        hospital = 1;
      }
      try {
        const res = await this.createFeedback({
          name: this.name,
          cnic: this.cnic,
          mobile: this.mobile,
          hospital: hospital,
        });

        if (res.status === 201) {
          this.displaySuccess("Request created successfully");
          this.show = false;
          this.$refs.userDetailFormValidation.reset();
          this.reset();
        }
      } catch (error) {
        this.show = false;
        this.reset();
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.cnic = "";
      this.mobile = "";
      this.hospital = "";
      this.$refs.userDetailFormValidation.reset();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";

label {
  font-size: 120%;
  font-weight: bolder;
}

.scroll-area-size {
  height: 30vh;
}

.scroll-area-size ul {
  height: 100%;
}

.modal-size {
  height: 65vh;
}

.quill-editor {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.quill-editor {
  overflow-y: hidden;
}

.vSelectStyle .vs__deselect {
  fill: #fff !important;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.text-black{
  color: black !important;
 
}
::placeholder {
  color: black;
}
label {
  font-size: 110%;
  font-weight: 400;
  color: #000;
}
</style>
